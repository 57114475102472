/* ======================================================================== */
/* 54. splitText */
/* ======================================================================== */
.split-text, .split-chars
	opacity: 0
	visibility: hidden
.split-text__line
	overflow: hidden
.split-chars__line
	display: inline
	overflow: hidden
