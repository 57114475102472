/* ======================================================================== */
/* 32. section404 */
/* ======================================================================== */
.section-404__header
	p
		margin-top: 3em
.section-404__wrapper-button
	margin-top: 30px
.section-404__big
	font-size: 300px
	font-weight: bold
	line-height: 1
	opacity: .05
	transform: rotate(90deg)
	transform-origin: right center
	white-space: nowrap
	position: absolute
	top: calc(100% - 240px)
	right: 240px

@media screen and (max-width: $xxl)
	.section-404__big
		top: calc(100% - 180px)
		right: 180px

@media screen and (max-width: $xl)
	.section-404__big
		font-size: 200px
		top: calc(100% - 120px)
		right: 120px

@media screen and (max-width: $md)
	.section-404
		text-align: center
		.section__headline
			margin-left: auto
			margin-right: auto
	.section-404__big
		position: relative
		top: auto
		right: auto
		transform: none
		font-size: 150px
