/* ======================================================================== */
/* 59. widget */
/* ======================================================================== */
.widget
	p
		&:last-of-type
			margin-bottom: 0
	select
		width: 100%
		max-width: 100%
	ul:not(.social)
		+reset-ul
		text-align: left
		li
			display: block
			margin-bottom: 20px
			a
				&:hover
					color: var(--color-accent-primary)
			&:last-child
				margin-bottom: 0
		ul
			width: 100%
			padding-left: 1em
			margin-top: 1em
	ul
		li
			a
				background-image: none

.widgettitle
	display: block
	font-family: $font-secondary
	font-size: 16px
	font-weight: 500
	line-height: 1.5
	letter-spacing: 1px
	text-transform: uppercase
	margin-bottom: 1.5em
	&:before
		content: ''
		display: block
		width: 100%
		height: 2px
		background-color: $dark
		margin-bottom: 0.5em
