/* ======================================================================== */
/* 57. typography */
/* ======================================================================== */
h1, h2, h3, h4, h5
	font-family: $font-primary
h6
	font-family: $font-secondary

h1
	font-size: 54px
	font-weight: 600
	line-height: 1.59
h2
	font-size: 33px
	font-weight: 600
	line-height: 1.5
h3
	font-size: 27px
	font-weight: 300
	line-height: 1.67
	margin-bottom: 1em
h4
	font-size: 24px
	font-weight: 600
	line-height: 1.67
h5
	font-size: 20px
	font-weight: 600
	line-height: 1.67
h6
	font-size: 16px
	font-weight: 500
	line-height: 1.5
	letter-spacing: 1px
	text-transform: uppercase
p
	font-weight: 300
	margin-top: 1em
	margin-bottom: 1em
strong
	font-weight: 600
small
	font-size: 14px
.heading-light
	font-weight: 300
a
	+trans1
	color: inherit
	background-image: linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, $brown-grey-2 0px, rgba(0,0,0,0) 1px, rgba(0,0,0,0) 100px)
	&:hover, &:focus
		text-decoration: none
		color: inherit
		opacity: .3
	&:focus
		color: inherit
		outline: none

@media screen and (max-width: $xxl)
	h1
		font-size: 43px
	h2
		font-size: 26px
	h3
		font-size: 24px
	h4
		font-size: 19px
	h5
		font-size: 16px
	h6
		font-size: 16px
@media screen and (max-width: $md)
	h1
		font-size: 28px
	h2
		font-size: 24px
	h3
		font-size: 19px
	h4
		font-size: 17px
	h5
		font-size: 16px
	h6
		font-size: 16px
	small
		display: inline-block
		font-size: 12px
