/* ======================================================================== */
/* 55. spinner */
/* ======================================================================== */
$offset: 202
$duration: 1.2s

.spinner
	position: fixed
	left: 120px
	bottom: calc(var(--fix-bar-vh, 30px) + 15px)
	width: 30px
	height: 30px
	z-index: 10000
	animation: rotator $duration ease-in-out infinite
	opacity: 0
	visibility: hidden

@keyframes rotator
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(270deg)

.spinner__path
	stroke: #b0b0b0
	stroke-dasharray: $offset
	stroke-dashoffset: 0
	transform-origin: center
	animation: dash $duration ease-in-out infinite

@keyframes dash
	0%
		stroke-dashoffset: $offset
		transform: rotate(0deg)
	50%
		stroke-dashoffset: $offset / 4
		transform: rotate(135deg)
	100%
		stroke-dashoffset: $offset
		transform: rotate(450deg)

@media screen and (max-width: $xxl)
	.spinner
		left: 80px
@media screen and (max-width: $xl)
	.spinner
		left: 30px
