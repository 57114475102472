/* ======================================================================== */
/* 63. widgetLogo */
/* ======================================================================== */
.widget_ruben_logo
	max-width: 360px
	.logo
		margin-bottom: 2em

@media screen and (max-width: $md)
	.widget_ruben_logo
		max-width: 100%
		.logo
			margin-bottom: 1em
