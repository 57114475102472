/* ======================================================================== */
/* 60. widgetArchive */
/* ======================================================================== */
.widget_archive
	ul
		li
			display: flex !important
			justify-content: space-between
			align-items: center
			flex-wrap: wrap
			span
				color: $dark
