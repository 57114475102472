/* ======================================================================== */
/* 26. preloader */
/* ======================================================================== */
.preloader
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	opacity: 0
	visibility: hidden
	z-index: 400
.preloader__curtain
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
.preloader__curtain_black
	background-color: $dark-1
.preloader__curtain_white
	background-color: $very-light-pink
