/* ======================================================================== */
/* 44. sectionVideo */
/* ======================================================================== */
.section-video
	display: flex
	align-items: center
	justify-content: center
	width: 100%
.section-video__link
	position: relative
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	background-image: none
	width: 100%
	z-index: 100
	cursor: none
	&:hover
		opacity: 1
		.section-video__icon
			&:after
				transform: scale(1)
	&:focus
		opacity: 1
.section-video__icon
	position: relative
	display: inline-flex !important
	align-items: center
	justify-content: center
	width: 80px
	height: 80px
	border-radius: 100%
	margin-top: 30px
	&:focus
		outline: none
	&:after
		content: ''
		display: block
		border-radius: 100%
		border: 1px solid $brown-grey
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		transform: scale(0)
		+trans1
	i
		font-size: 32px
		line-height: 2

@media screen and (max-width: $sm)
	.section-video__icon
		&:after
			transform: scale(1)
