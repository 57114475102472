html
	font-size: $em-base
	-moz-osx-font-smoothing: grayscale
	-webkit-font-smoothing: antialiased
	text-rendering: optimizeLegibility
	overflow-x: hidden
	width: 100%
	height: 100%
body
	position: relative
	width: 100%
	min-width: 320px
	font-family: $font-primary
	background-color: $light-blue-grey-2
	color: $black
	font-size: 100%
	line-height: 1.75
	overflow-x: hidden
	word-wrap: break-word
.body_lock-scroll
	overflow-y: hidden
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
.body_custom-cursor
	cursor: none
img
	max-width: 100%
	height: auto
.container-fluid
	padding-left: 120px
	padding-right: 120px
.container
	padding-left: 25px
	padding-right: 25px
.row
	margin-left: -25px
	margin-right: -25px
.col, [class*='col-']
	padding-left: 25px
	padding-right: 25px
.no-gutters
	margin-left: 0 !important
	margin-right: 0 !important
.page-wrapper
	z-index: 50
	
@media screen and (max-width: $xxl)
	.container-fluid
		padding-left: 80px
		padding-right: 80px
@media screen and (max-width: $xl)
	.container-fluid
		padding-left: 50px
		padding-right: 50px
@media screen and (max-width: $md)
	.container-fluid, .container
		padding-left: 30px
		padding-right: 30px
	.row
		margin-left: -15px
		margin-right: -15px
	.col, [class*='col-']
		padding-left: 15px
		padding-right: 15px
