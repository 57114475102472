/* ======================================================================== */
/* 12. figureProperty */
/* ======================================================================== */
.figure-property
	width: 100%
.figure-property__headline
	width: 100%
	height: 2px
	background-color: $dark
	margin-bottom: 16px
.figure-property__wrapper-heading_bold
	h6
		font-size: 20px
		font-family: $font-primary
		font-weight: 600
		text-transform: none
		letter-spacing: 0
.figure-property__list
	+reset-ul
.figure-property__item
	display: block
	padding-top: 8px
	padding-bottom: 8px

@media screen and (max-width: $md)
	.figure-property__wrapper-heading_bold h6
		font-size: 16px
