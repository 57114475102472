/* ======================================================================== */
/* 41. sectionMap */
/* ======================================================================== */
.section-map__wrapper-form
	display: flex
	align-items: center
	justify-content: center
	position: absolute
	top: 80px
	right: 120px
	bottom: 80px
	padding: 100px 80px

@media screen and (max-width: $xxl)
	.section-map__wrapper-form
		right: 80px
		padding: 80px 60px

@media screen and (max-width: $xl)
	.section-map__wrapper-form
		right: 50px

@media screen and (max-width: $md)
	.section-map__wrapper-form
		position: relative
		top: auto
		right: auto
		bottom: auto
		padding: 80px 30px
