/* ======================================================================== */
/* 19. lazy */
/* ======================================================================== */
.lazy
	background-color: $white
	animation-duration: 0.9s
	animation-iteration-count: infinite
	animation-direction: alternate
	animation-timing-function: ease-in-out
.lazy__img:not(img)
	background-size: cover
	background-position: center center
.lazy-bg
	width: 100%
	height: 100%

@keyframes loading
	0%
		opacity: 0
	to
		opacity: .3
