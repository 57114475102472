/* ======================================================================== */
/* 61. widgetCalendar */
/* ======================================================================== */
#wp-calendar
	width: 100%
	text-align: center
	caption
		caption-side: top
		width: 100%
		text-align: center
		padding-top: 0
		padding-bottom: 10px
	th
		font-weight: 600
		padding: 5px
		text-align: center
	td
		padding: 5px
		color: $dark
		&#next
			text-align: right
		&#prev
			text-align: left
	tr
		border-bottom: none
