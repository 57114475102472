/* ======================================================================== */
/* 51. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials__slide
	display: flex
	align-items: center
	padding: 50px 0
	blockquote
		font-size: 20px
		line-height: 1.75
		font-style: italic
		font-weight: 300
		margin-top: 0
		margin-bottom: 0
.slider-testimonials__footer
	border-top: 1px solid $borders-dark
	text-align: center
	padding-top: 30px
.slider-testimonials__wrapper-avatar
	border-radius: 100%
	overflow: hidden
	max-width: 164px
	max-height: 164px
	flex: 1 0 auto
	margin-right: 50px
.slider-header-testimonials
	border-bottom: 1px solid $borders-dark
	padding-bottom: 25px
	&:before, &:after
		content: ''
		display: block
		height: 100%
		width: 50px
		position: absolute
		top: 0
		bottom: 0
		z-index: 50
	&:before
		left: 0
		background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)
	&:after
		right: 0
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)
		
.slider-header-testimonials__slide
	text-align: center
	cursor: pointer
	span
		display: block
		opacity: .3
		font-size: 20px
		font-weight: bold
		white-space: nowrap
		+trans1
	&.swiper-slide-active
		span
			opacity: 1

@media only screen and (max-width: $md)
	.slider-testimonials__wrapper-avatar
		max-width: 80px
		max-height: 80px
		margin-right: 0
		margin-bottom: 1em
	.slider-testimonials__slide
		flex-direction: column
		text-align: center
		padding: 30px 0
		blockquote
			font-size: 16px
	.slider-header-testimonials
		padding-bottom: 15px
	.slider-header-testimonials__slide span
		font-size: 16px
