@function rem($size)
	$remSize: $size / $em-base
	@return #{$remSize}rem
@mixin trans1
	transition: all 0.4s cubic-bezier(0.785, 0.135, 0.150, 0.860)
@mixin trans2
	transition: all 0.6s cubic-bezier(0.785, 0.135, 0.150, 0.860)
@mixin reset-ul
	list-style-type: none
	padding: 0
	margin: 0

@mixin small-caps
	font-family: $font-secondary
	font-size: 13px
	line-height: 1.3
	font-weight: 500
	letter-spacing: 1px
	text-transform: uppercase
	@media only screen and (max-width: 991px)
		font-size: 11px
		letter-spacing: 0.8px
		
@mixin link-underline
	&:after, &:before
		content: ''
		position: absolute
		bottom: 0
		width: 0
		right: 0
		height: 1px
		background-color: $white
	&:before
		transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s
	&:after
		transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83)
	&:after, &:before
		width: calc(100% - 10px)
		left: 0

@mixin material-icons
	font-family: 'Material Icons'
	font-style: normal
	letter-spacing: normal
	text-transform: none
	white-space: nowrap
	word-wrap: normal
	direction: ltr
	-webkit-font-feature-settings: 'liga'
	font-feature-settings: 'liga'
	ms-font-feature-settings: 'liga'
	-webkit-font-smoothing: antialiased
	text-rendering: optimizeLegibility
