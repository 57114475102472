/* ======================================================================== */
/* 22. logo */
/* ======================================================================== */
.logo
	display: block
	font-family: $font-secondary
	font-size: 16px
	line-height: 1.3
	font-weight: 500
	letter-spacing: 1px
	text-transform: uppercase
	color: $black
a.logo:focus
	color: $black
	opacity: 1
a.logo
	background-image: none
