/* ======================================================================== */
/* 45. select */
/* ======================================================================== */
select
	display: block
	border: 1px solid $borders-dark
	padding: 10px 35px 10px 15px
	background-color: transparent
	outline: none
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none
	background-image: url('../img/general/select.png')
	background-position: right 15px bottom 50%
	background-repeat: no-repeat
	background-size: 6px 4px
