/* ======================================================================== */
/* 4. comments */
/* ======================================================================== */
.comment-list
	+reset-ul
	> li
		padding-top: 30px
		padding-bottom: 30px
		ol.children
			+reset-ul
			padding-left: 4%
			li
				padding-top: 30px
				padding-bottom: 30px
			li:last-child
				padding-bottom: 0
	> li:not(:last-child)
		border-bottom: 1px solid $borders-dark
	> ol
		+reset-ul
.comment-author
	display: inline-block
	.avatar
		position: relative
		width: 100%
		max-width: 100px
		max-height: 100px
		border-radius: 100%
		margin-right: 1.5em
		@media only screen and (max-width: $md)
			width: 50px
			height: 50px

.comment-meta
	display: flex
	align-items: center
	justify-content: space-between
	margin-bottom: 0.5em
	a
		background-image: none
	@media only screen and (max-width: $md)
		flex-wrap: wrap

.comment-metadata
	+small-caps
	margin-left: 10px
	a:not(.comment-edit-link)
		color: $brown-grey
		&:hover
			color: var(--color-accent-secondary)
	a
		border-bottom: none !important
	@media only screen and (max-width: $md)
		margin-top: 5px
		margin-left: 0
.comment-body
	display: flex
	.fn
		font-size: 20px
		font-weight: 600
		line-height: 1.44
		color: $dark
		a
			border-bottom: none
.comment-author
	flex: 0 0 auto
.comment-content
	width: 100%
.reply
	display: inline-block
.comment-reply-link
	+small-caps
	border-bottom: none !important
	margin-right: 24px
	background-image: none
	&:before
		content: '\f112'
		font-family: 'FontAwesome'
		text-transform: none
		margin-right: 8px
.comment-respond
	margin-top: 10px
.comment-edit-link
	+small-caps
	color: var(--color-accent-primary)
	border-bottom: none !important
	background-image: none
	&:before
		content: '\f044'
		font-family: 'FontAwesome'
		text-transform: none
		margin-right: 6px
